import React, { useLayoutEffect, useState } from 'react'
import api from './api'
import '../css/Login.css'


import { Alert, message, Tag, Typography, Table } from 'antd'

import { ProCard } from '@ant-design/pro-components'

const { Text, Title, Link } = Typography

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '节点名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: ((status) => {
            if (status) {
                return (
                    <Tag color="success">在线</Tag>
                )
            }
            return (
                <Tag color="error">离线/故障</Tag>
            )
        }),
    },
    {
        title: '在线率',
        dataIndex: 'preciseRatio',
        key: 'preciseRatio',
        render: ((preciseRatio) => {
            if (preciseRatio) {
                return (
                    <Tag color="processing">{preciseRatio}%</Tag>
                )
            }
        }),
    },
    {
        title: '检测时间',
        dataIndex: 'ctime',
        key: 'ctime',
        render: ((ctime) => {
            if (ctime) {
                return (
                    <Tag color="default">{ctime}</Tag>
                )
            }
        }),
    },
]

//  节点监控状态布局
function Status(props) {
    const [data, setData] = useState({ Fault: [] })

    useLayoutEffect(() => {
        let name = 'userStatusData'
        if (localStorage.getItem(name)) {
            let Data = JSON.parse(localStorage.getItem(name))
            setData(Data)
        }
        api.get('/api/v2/get_status_data')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    return (
        <div>
            {data.Fault.length >= 1 ?
                <>
                    <Alert
                        message="节点监控状态"
                        description="在这里可以实时看到N2YUN的服务状态,每 30 秒自动更新数据"
                        style={{ marginBottom: 20 }}
                    />
                    <Alert
                        type="error"
                        message="离线/故障节点"
                        description={data.Fault.map((i, key) => (key == 0 ? i.name : '、' + i.name))}
                        style={{ marginBottom: 20 }}
                    />
                </>
                :
                <Alert
                    message="节点监控状态"
                    description="在这里可以实时看到N2YUN和FiveM的服务状态,每 30 秒更新数据"
                    style={{ marginBottom: 20 }}
                />
            }

            <ProCard title="N2YUN 服务状态" bordered headerBordered style={{ marginBottom: 20 }}>
                <Table
                    columns={columns}
                    dataSource={data.n2yun}
                    pagination={false}
                    style={{
                        marginTop: 20
                    }}
                />
            </ProCard>
            {/* <ProCard title="FiveM 服务状态" bordered headerBordered>
                <Table
                    columns={columns}
                    dataSource={data.fivem}
                    pagination={false}
                    style={{
                        marginTop: 20
                    }}
                />
            </ProCard> */}
        </div>
    )
}

export default Status