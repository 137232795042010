import React, { useState, useEffect } from 'react'
import api from '../api'

import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Typography, Tag, message, Modal, Input, Button, Alert, Table, Tooltip, Drawer, Descriptions } from 'antd'

import { ProCard } from '@ant-design/pro-components'
const { Text, Title } = Typography
const { TextArea } = Input


//  表格
const columns = [
    {
        title: '任务ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        render: (state, record) => (
            state === 1 ?
                <Tag icon={<SyncOutlined spin />} color="processing" >处理中</Tag>
                : state === 2 ? <>
                    <Tag icon={<CheckCircleOutlined />} color="success" >已完成</Tag>
                    {record.data.awshtml.Data.error.length > 1 ?
                        <Tooltip title="请点击右边的查看内容来自行检查未处理的链接">
                            <Tag icon={<CloseCircleOutlined />} color="error" >含有未处理链接</Tag>
                        </Tooltip>
                        : ''}
                </> :
                    <Tag icon={<ExclamationCircleOutlined />} color="warning" >排队中</Tag>
        ),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Tag color="processing" className='clogs-check' data-id={record.id}>查看内容</Tag>
        ),
    },
];

//  控制台-一键去墙
function Aws(props) {
    const [data, setData] = useState([])
    const [htmlData, setHtmlData] = useState({ Data: [] })
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [status, setStatus] = useState(false)
    const [awsData, setAwsData] = useState({})
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [update, setUpdate] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.get('/api/v2/get_aws_task')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    setLoading(false)
                    setData(Data)
                    // getStatus()
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])


    // //  获取节点通信
    // function getStatus() {
    //     api.get('/api/v2/get_status', {
    //         params: {
    //             name: 'aws'
    //         }
    //     })
    //         .then((res) => {
    //             if (res.data.code === 200) {
    //                 setStatus(false)
    //             } else {
    //                 setStatus(true)
    //             }
    //         })
    //         .catch((error) => {
    //             message.error('网络异常,无法连接服务器')
    //         })
    // }

    function onChange(e) {
        setHtmlData({
            ...htmlData,
            html: e.target.value,
        })
    }

    const onClose = () => {
        setOpen(false)
    }

    //  开始处理
    function start() {
        if (!htmlData.html) {
            return message.warning('请粘贴HTML代码再处理')
        }
        api.post('/api/v2/aws', {
            data: JSON.stringify({
                value: htmlData.html
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setModal(false)
                    message.success('任务已提交,请稍等后台处理...')
                    setUpdate(Date.now())
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  添加任务
    function addTask() {
        setModal(true)
    }

    //  复制内容
    function copy() {
        message.success('已复制内容')
        navigator.clipboard.writeText(awsData.data.awshtml.html)
    }

    //  处理内容
    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let id = parseInt(event.target.dataset.id)
                let Data = data
                for (let i = 0; i < Data.length; i++) {
                    if (Data[i].id === id) {
                        if (Data[i].data.awshtml.Data.error.length >= 1) {
                            setError(true)
                        } else {
                            setError(false)
                        }
                        setAwsData(Data[i])
                        setOpen(true)
                        return
                    }
                }
                message.error('复制失败,找不到数据')
                return
            }
        }
    }

    const Refresh = () => {
        setUpdate(Date.now())
    }

    return (
        <div>
            {/* <Alert
                message="远程缓存服务离线"
                description="系统检查到此服务已离线或异常,这可能会影响部分功能,请稍后再次刷新查看服务状态,如果多次仍然还是一样,请联系站务进行处理"
                type="error"
                showIcon
                style={{ marginBottom: 20, display: status ? '' : 'none' }}
            /> */}

            <Alert
                message="业务调整通知"
                description="现已调整为分级使用, 例如: NV1 和 NV2 级别的会员, 各享受不同的权益, 近期将会持续调整本网站的会员机制, 请关注Q群群公告或网站公告。"
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
            />

            <Alert message="任务队列有效期默认为: 24 小时,请及时刷新观看任务的状态" type="info" />

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 一键去墙 <Tag color="processing">NV2会员专享</Tag></Title>
                <Button onClick={addTask} style={{ float: 'right', marginBottom: 20, marginRight: 10 }}>添加任务</Button>
                <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    style={{ marginTop: 20 }}
                    onRow={(record) => {
                        return {
                            onClick: onClick, // 点击
                        }
                    }}
                />
            </ProCard>

            {/* {
                props.verifyAy('admin') ?
                    <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                        <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 一键去墙 <Tag color="processing">会员专享</Tag></Title>
                        <Button onClick={addTask} style={{ float: 'right', marginBottom: 20, marginRight: 10 }}>添加任务</Button>
                        <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            style={{ marginTop: 20 }}
                            onRow={(record) => {
                                return {
                                    onClick: onClick, // 点击
                                }
                            }}
                        />
                    </ProCard>
                    : null
            } */}


            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="一键去墙-任务详情">
                    <Descriptions.Item span={3} style={{ display: error ? '' : 'none' }}>
                        <Alert
                            message="警告"
                            description="此任务存在处理失败的链接,请自行检查并解决"
                            type="warning"
                            showIcon
                            style={{ width: '100%' }}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="任务ID" span={3}>
                        {awsData.id}
                    </Descriptions.Item>
                    <Descriptions.Item label="全部链接" span={3}>
                        <Tag color="processing">{awsData.data ? awsData.data.awshtml.Data.num : 0}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="已处理链接" span={3}>
                        <Tag color="success">{awsData.data ? awsData.data.awshtml.Data.ok.length : 0}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="无需处理链接" span={3}>
                        <Tag color="default">{awsData.data ? awsData.data.awshtml.Data.none.length : 0}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="处理失败链接" span={3}>
                        <Tooltip title={awsData.data ? awsData.data.awshtml.Data.error.map((i, k) => {
                            return (
                                ' [' + i.url + '] '
                            )
                        }) : ''}>
                            <Tag color="error">{awsData.data ? awsData.data.awshtml.Data.error.length : 0}</Tag>
                        </Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="内容" span={3}>
                        <TextArea rows={8} value={awsData.data ? awsData.data.awshtml.html : ''} />
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" onClick={copy}>复制内容</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>

            <Modal
                title="添加任务"
                centered
                open={modal}
                onOk={start}
                onCancel={() => setModal(false)}
                keyboard={false}
                maskClosable={false}
                width={1000}
            >
                <TextArea rows={18} placeholder="请粘贴HTML代码..." style={{ marginTop: 10, width: '100%' }} value={htmlData.html} onChange={onChange} />
            </Modal>
        </div >
    )
}

export default Aws;