import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Tag, Typography, message, Modal, Input, Table, Tooltip, Alert } from 'antd'

import { ProCard } from '@ant-design/pro-components'

const { Paragraph, Title } = Typography

//  控制台-NZ云存储
function Nzcos(props) {
    const [data, setData] = useState({ gip: [], token: '', member: '普通' })

    useEffect(() => {
        let name = 'userMemberData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/user/get', {
            params: {
                name: 'member'
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    return (
        <div>
            <Alert
                message="使用限制"
                description="目前机制是图片单文件大小为5mb,视频单文件大小为50mb,同时不得滥用视频上传的接口,如发现大量上传视频文件则会进一步处理的!"
                type="info"
                showIcon
                style={{ marginBottom: 20 }}
            />
            <ProCard style={{ width: '100%' }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 20, fontWeight: 400 }} > NZ云存储 <Tag color="processing">NV1会员专享</Tag></Title>
                <Title level={5} style={{ marginTop: 0, marginBottom: 10, fontWeight: 400 }} > 图片接口</Title>
                <Paragraph
                    copyable={{
                        text: `https://u.n2yun.com.cn/api/v2/upload/imgs?token=${data.token}`,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    接口地址: <Tag color="processing">https://u.n2yun.com.cn/api/v2/upload/imgs?token={data.token}</Tag>
                </Paragraph>
                <Title level={5} style={{ marginTop: 20, marginBottom: 10, fontWeight: 400 }} > 视频接口</Title>
                <Paragraph
                    copyable={{
                        text: `https://u.n2yun.com.cn/api/v2/upload/video?token=${data.token}`,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    接口地址: <Tag color="processing">https://u.n2yun.com.cn/api/v2/upload/video?token={data.token}</Tag>
                </Paragraph>
            </ProCard>
        </div>
    )
}

export default Nzcos;